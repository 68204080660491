import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import Certified from "../../assets/Leak/save-energy.svg";
import Design from "../../assets/Leak/notification-bell.svg";
import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "0px 80px 0px 80px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item lg={3} xs={10} sm={4} md={4}>
          <div align="center" className={classes.imageContainer}>
            <Certified alt="toronto-plumber" height="auto" width="auto" />
          </div>
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={7}>
          <h2 className={classes.title}>Save energy.</h2>
          <p className={classes.paragraph2}>
            Running water can add up a significant amount of cost to your water
            bill every month. Our certified team of Toronto plumbers can install
            one of the most recommended leak detection systems in your home. In
            case of any leaks, you will be notified as to which fixture needs to
            be replaced or repaired.
          </p>
        </Grid>

        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={7}
          className={classes.orderContainer}
        >
          <h2 className={classes.title}> Get notified of freezing pipes.</h2>
          <p className={classes.paragraph2}>
            With the Phyn Plus you will be notified when your pipes are
            experieincing freezing. These real time notifications will help you
            identify when a major plumbing issue may occur. Without the
            notifications your pipes are at risk of freezing and bursting
            causing major flooding in your home.
          </p>
        </Grid>
        <Grid item lg={3} xs={10} sm={4} md={4}>
          <div align="center" className={classes.imageContainer}>
            <Design alt="toronto-plumbers" height="auto" width="auto" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
