import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,

  image2: {
    width: "50%",
    height: "400px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "40px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "50%",
      height: "350px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      width: "auto",
      height: "210px",
      display: "flex",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div style={{ padding: "0px 0px 80px 0px" }}>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              HOW IT WORKS
            </h2>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <div align="center">
            <iframe
              className={classes.image2}
              src="https://www.youtube.com/embed/QJ2f5Fvms8s"
              frameBorder="0"
              allow="encrypted-media; gyroscope;"
              allowFullScreen
              align="center"
              title="Leak detection system"
            />
          </div>
        </Grid>
      </div>
    </div>
  );
}
