import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

import Pressure from "../../assets/Leak/leak-detection-2.svg";
import Home from "../../assets/Leak/leak-detection.svg";
import Shut from "../../assets/Leak/leak-detection-4.svg";
import Install from "../../assets/Leak/leak-detection-3.svg";

const useStyles = makeStyles({
  ...styles,
  titleRes: {
    color: `"black" + "!important"`,
    marginTop: "0px",
    textTransform: "uppercase",
    fontWeight: "500",
    fontFamily: "poppins",
    fontSize: "20px",
    "@media screen and (max-width:500px)": {
      fontSize: "px",
    },
  },
  paragraphRes: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "300",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (max-width:500px)": {
      textAlign: "center",

      paddingRight: "0px",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div style={{ padding: "60px 0px 0px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              Leak Detection Features
            </h2>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          direction="row"
          style={{ padding: "80px 0px 0px 0px" }}
        >
          <Grid item lg={5} md={12} sm={4} xs={11}>
            <Grid item lg={12} md={10} sm={11} xs={11}>
              <h3 align="center" className={classes.titleRes}>
                High Definition Pressure Sensing
              </h3>
            </Grid>

            <Grid
              container
              justify="center"
              direction="row"
              alignItem="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={2} xs={6} sm={6} md={2}>
                <div align="center">
                  <Pressure alt="toronto-plumbers" height="100%" width="100%" />
                </div>
              </Grid>
              <Grid item lg={8} md={6} sm={11} xs={11}>
                <p className={classes.paragraphRes}>
                  Phyn uses patented, high-definition pressure wave sensing to
                  catch plumbing issues ranging from faucet drips to pinhole
                  leaks to frozen pipe bursts.
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={5} md={12} sm={11} xs={11}>
            <Grid item lg={12} md={10} sm={11} xs={11}>
              <h3 align="center" className={classes.titleRes}>
                Intelligence
              </h3>
            </Grid>

            <Grid
              container
              justify="center"
              direction="row"
              alignItem="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={2} xs={6} sm={6} md={2}>
                <div align="center">
                  <Home alt="toronto-plumbers" height="100%" width="100%" />
                </div>
              </Grid>
              <Grid item lg={8} md={6} sm={11} xs={11}>
                <p className={classes.paragraphRes}>
                  Phyn learns about your plumbing system as soon as it’s
                  connected, and gets to know the unique signatures of each
                  fixture better over time to recognize the subtle differences
                  between a bath filling and pipe leaking.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          direction="row"
          style={{ padding: "80px 0px 0px 0px" }}
        >
          <Grid item lg={5} md={12} sm={11} xs={11}>
            <Grid item lg={12} md={10} sm={11} xs={11}>
              <h3 align="center" className={classes.titleRes}>
                Built-in Shutoff Valve
              </h3>
            </Grid>

            <Grid
              container
              justify="center"
              direction="row"
              alignItem="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={2} xs={6} sm={6} md={2}>
                <div align="center">
                  <Shut alt="toronto-plumbers" height="100%" width="100%" />
                </div>
              </Grid>
              <Grid item lg={8} md={6} sm={11} xs={11}>
                <p className={classes.paragraphRes}>
                  Phyn uses patented, high-definition pressure wave sensing to
                  catch plumbing issues ranging from faucet drips to pinhole
                  leaks to frozen pipe bursts.
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={5} md={12} sm={11} xs={11}>
            <Grid item lg={12} md={10} sm={11} xs={11}>
              <h3 align="center" className={classes.titleRes}>
                Professional Installation
              </h3>
            </Grid>

            <Grid
              container
              justify="center"
              direction="row"
              alignItem="center"
              alignContent="center"
              spacing={4}
            >
              <Grid item lg={2} xs={6} sm={6} md={2}>
                <div align="center">
                  <Install alt="toronto-plumbers" height="100%" width="100%" />
                </div>
              </Grid>
              <Grid item lg={8} md={6} sm={11} xs={11}>
                <p className={classes.paragraphRes}>
                  Phyn Plus is installed by a member of the Uponor Pro Squad, a
                  nationwide network of expertly-trained plumbers and water
                  specialists.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
